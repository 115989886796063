/* General form styling */
.auth-form {
  background: #fff;
  padding: 20px 40px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  margin: 2rem auto;
}

.auth-form h2 {
  color: #333;
  margin-bottom: 1.5rem;
  text-align: center;
}

.auth-form form {
  display: flex;
  flex-direction: column;
}

.auth-form input {
  padding: 12px;
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

/* General button styling for consistency */
button, .auth-form button {
  padding: 10px 20px;
  border-radius: 20px; /* Rounded rectangle shape */
  border: none;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  margin: 10px 0; /* Adjust spacing around buttons */
  font-weight: bold; /* Makes button text bold */
}

/* Specific styling for auth form buttons */
.auth-form button {
  background-color: #0056b3; /* Zoom's blue */
  color: white;
}

.auth-form button:hover {
  background-color: #003d82;
}

/* Paragraph and link styling within forms */
.auth-form p, .auth-form a {
  margin-top: 1rem;
  text-align: center;
  color: #0056b3; /* Zoom's blue */
  cursor: pointer;
}

.auth-form p:hover, .auth-form a:hover {
  text-decoration: underline;
}

/* Specific classes for Start/Stop buttons for more customization */
.button-start {
  background-color: #4CAF50; /* Green for start */
  color: white;
}

.button-stop {
  background-color: #f44336; /* Red for stop */
  color: white;
}

/* Ensure the hover effect for start/stop buttons */
.button-start:hover {
  background-color: #45a049;
}

.button-stop:hover {
  background-color: #d32f2f;
}

.form-logo {
  max-width: 150px; /* Adjust based on your logo size */
  display: block;
  margin: 0 auto 20px; /* Centers the logo and adds space below it */
}
