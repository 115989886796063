.home {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url('https://th.bing.com/th/id/R.87cae5211149bf8c2b2be03b1596881d?rik=xx8M%2f0NKBwOzYA&pid=ImgRaw&r=0');
  background-size: cover;
  color: #0056b3;
  background-position: center;
  padding: 20px; /* Ensure there's space around your card on small screens */
}

.home-card {
  background-color: #ffffff; /* Or any color that fits your design */
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 40px;
  max-width: 600px; /* Adjust based on your content */
  width: 100%;
  text-align: center;
}

.button-start, .button-stop {
  margin: 10px;
  padding: 10px 20px;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  font-weight: bold;
  color: white;
}

.button-start {
  background-color: #0056b3; /* Green */
}

.button-stop {
  background-color: #f44336; /* Red */
}

.radio-group {
  margin-top: 20px;
}

.radio-group label {
  color: #0056b3; /* Ensure visibility against the card's background */
  margin-right: 15px;
  font-size: 1rem;
}

input[type="radio"] {
  margin-right: 5px;
}


.card-logo {
    max-width: 120px; /* Adjust the size as needed */
    height: auto; /* Maintain aspect ratio */
    display: block;
    margin: 20px auto; /* Centers the logo and adds space above and below */
  }
  