body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* 
  background-color: #FFFFFF;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpolygon fill='%23fdfdfd' points='800 100 0 200 0 800 1600 800 1600 200'/%3E%3Cpolygon fill='%23fafafa' points='800 200 0 400 0 800 1600 800 1600 400'/%3E%3Cpolygon fill='%23f8f8f8' points='800 300 0 600 0 800 1600 800 1600 600'/%3E%3Cpolygon fill='%23f5f5f5' points='1600 800 800 400 0 800'/%3E%3Cpolygon fill='%23f3f3f3' points='1280 800 800 500 320 800'/%3E%3Cpolygon fill='%23f0f0f0' points='533.3 800 1066.7 800 800 600'/%3E%3Cpolygon fill='%23EEE' points='684.1 800 914.3 800 800 700'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover; 
  */
  background: none!important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

/* Custom CSS */
.centralise {
  margin: auto;
  text-align: center;
}