
#chats{
    visibility: hidden;
}

#secondPanel{
    display: none;
}


.panel {
    background-color: rgba(0, 0, 0, 0.3);
    /* Black with 30% opacity */
    border-radius: 15px;
    padding-top: 0;
    /* Remove padding on top */
    padding-bottom: 0;
    /* Remove padding on bottom */
    padding-left: 20px;
    /* Maintain padding on left and right */
    padding-right: 20px;
    width: 300px;
    /* Adjust width as needed */
    color: white;
    /* White text color */
    height: auto;
    /* Remove fixed height */
}

.panel h2 {
    text-align: center;
}

.name-list {
    list-style-type: none;
    padding: 0;
}

.person {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    /* Adjusted margin */
}

.person img {
    width: 30px;
    /* Adjust image size as needed */
    height: 30px;
    border-radius: 50%;
    /* This creates circular images */
    margin-right: 10px;
    border: 2px solid blue;
    /* Add a blue border */
}

.person img.zoom {
    width: 20px;
    height:20px;
    border: none;
    position: absolute;
    top:20px;
    left:-4px;
    z-index: 4;

}

/* Style for the profile */
.profile {
    display: flex;
    align-items: center;
}

/* Style for the profile image */
.profile img {
    width: 30px;
    /* Adjust size as needed */
    height: 30px;
    /* Adjust size as needed */
    border-radius: 50%;
    /* Make the image circular */
}

/* Style for the profile info */
.profile .info {
    margin-left: 20px;
    /* Adjust spacing between image and text */
}

/* Style for the name */
.profile .info span {
    font-size: 30px;
    /* Adjust font size as needed */
    font-weight: bold;
    /* Optionally bold the name */
}

/* Style for the paragraph */
.profile .info p {
    font-size: 18px;
    /* Adjust font size as needed */
    margin-top: 5px;
}

.panel:last-child {
    position: absolute;
    bottom: 20px;
    /* Adjust distance from bottom as needed */
    left: 20px;
    /* Adjust distance from the left as needed */
    display: none;
    /* Initially hide the panel */
}

/* Animation keyframes */
@keyframes slide-up {
    0% {
        transform: translateY(100%);
    }

    100% {
        transform: translateY(0);
    }
}

/* Animation class */
.slide-up-animation {
    animation: slide-up 0.5s ease forwards;
}

/* Style for the emojis */
.emoji {
    position: absolute;
    font-size: 24px;
    /* Adjust font size as needed */
    z-index: 999;
    /* Ensure emojis appear above other content */
}
