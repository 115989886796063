.virtual-camera {
  position: relative;
  height: 100vh;
  padding: 18px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  background-size: cover;
  background-position: center;
}

.messages-display {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  max-width: 500px;
  box-sizing: border-box;
}

.message {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.user-image {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  object-fit: cover;
}

.bottom-card {
  position: absolute; /* Adjusted for explicit positioning */
  left: 0; /* Start from the left */
  bottom: 0; /* Align to bottom */
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  max-width: 350px;
  box-sizing: border-box;
  animation: slideFromLeft 3.980s forwards; /* Apply animation to move from left to right */
  opacity: 0; /* Hide initially */
}

@keyframes slideFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
